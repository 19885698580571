<template>
  <section class="invoice-preview-wrapper">
    <b-row v-if="loaded" class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
              <div>
                <div class="logo-wrapper">
                  <h3 class="text-primary invoice-logo">{{ invoiceBranch.name }}</h3>
                </div>
                <p class="card-text mb-25">
                  {{ invoiceBranch.contact }}
                </p>
                <p class="card-text mb-25">
                  {{ invoiceBranch.address }}
                </p>

                <p class="card-text mb-0">
                  {{ invoiceBranch.email }}
                </p>
                <p class="card-text mb-1">
                  {{ invoiceBranch.phone }}
                </p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  {{ msg('Invoice') }}
                  <span class="invoice-number">#{{ transaction.invoiceNumber }}</span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    {{ msg('Date') }}:
                  </p>
                  <p class="invoice-date">
                    {{ $moment(transaction.creationDate, 'YYYY-MM-DD HH:mm').format("DD/MM/YY HH:mm") }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->
          <b-card-body class="invoice-padding pt-0">
            <b-row class="invoice-spacing">

              <!-- Col: Invoice To -->
              <b-col cols="12" xl="6" class="p-0">
                <h6 class="mb-2">{{ msg('Invoice To') }}:</h6>
                <div v-if="hasClient">
                  <h6 class="mb-25 text-capitalize">
                    <b-link @click="()=>$root.$emit('crm::client::select',client.id)">{{ transaction.company }}
                    </b-link>
                  </h6>
                  <h6 class="mb-25 text-capitalize">
                    <b-link @click="()=>$root.$emit('crm::client::select',client.id)">{{ client.firstname }}
                      {{ client.lastname }}
                    </b-link>
                  </h6>
                </div>
                <div v-else>
                  <span v-if="shippingAddress.fullName != null">{{ shippingAddress.fullName }}</span>
                </div>

                <p class="card-text mb-25" v-if="shippingAddress.address != null &&shippingAddress.address!==''">
                  {{ shippingAddress.address }},
                  <span v-if="shippingAddress.zip != null">{{ shippingAddress.zip }},</span> {{ shippingAddress.city }}
                </p>
                <p class="card-text mb-25" v-if="shippingAddress.country != null">
                  {{ getCountry(shippingAddress.country).name }}
                </p>
                <div v-if="hasClient">
                  <p class="card-text mb-0">
                    {{ client.email }}
                  </p>
                </div>
                <div v-else>
                  <p v-if="shippingAddress.email!=null" class="card-text mb-0">
                    {{ shippingAddress.email }}
                  </p>
                </div>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col
                  xl="6"
                  cols="12"
                  class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
                <div>
                  <h6 class="mb-2">
                    {{ msg('Payment Details') }}:
                  </h6>
                  <table>
                    <tbody>
                    <tr>
                      <td class="pr-1">
                        {{ msg('Total') }}:
                      </td>
                      <td><span class="font-weight-bold">{{ (transaction.amount).toFixed(2) }} {{
                          currencySymbol
                        }}</span></td>
                    </tr>
                    <template v-if="transaction.method === 'BANKING'">
                      <tr>
                        <td class="pr-1">
                          {{ msg('Bank name') }}:
                        </td>
                        <td>{{ transaction.bank_name }}</td>
                      </tr>
                      <tr v-if="transaction.bank_country != null">
                        <td class="pr-1">
                          {{ msg('Country') }}:
                        </td>
                        <td>{{ transaction.bank_country }}</td>
                      </tr>
                      <tr v-if="transaction.bank_iban != null">
                        <td class="pr-1">
                          IBAN:
                        </td>
                        <td>{{ transaction.bank_iban }}</td>
                      </tr>
                      <tr v-if="transaction.bank_swift != null">
                        <td class="pr-1">
                          {{ msg('swift code') }}:
                        </td>
                        <td>{{ transaction.bank_swift }}</td>
                      </tr>
                      <tr v-if="transaction.bank_branch != null">
                        <td class="pr-1">
                          {{ msg('Branch') }}:
                        </td>
                        <td>{{ transaction.bank_branch }}</td>
                      </tr>
                      <tr v-if="transaction.bank_account != null">
                        <td class="pr-1">
                          {{ msg('Bank Account') }}:
                        </td>
                        <td>{{ transaction.bank_account }}</td>
                      </tr>
                    </template>
                    <template v-if="transaction.method === 'CREDIT_CARD'">
                      <tr>
                        <td class="pr-1">
                          {{ msg("Credit card") }}:
                        </td>
                        <td>{{ transaction.cc_brand }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          {{ msg("Credit card Number") }}:
                        </td>
                        <td>{{ transaction.cc_last4 }}</td>
                      </tr>
                    </template>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite
              responsive
              :items="tableData"
              :fields="[
              {key: 'productName', label: msg('product')},
              { key: 'amount', label: msg('amount')},
              { key: 'nettoPrice', label: msg('netto')},
              { key: 'tax', label: msg('tax')},
              { key: 'price', label: msg('final Price')}]"
          >
            <template #cell(productName)="data">
              <b-link @click="$router.push('/product/edit/'+data.item.productId)">{{ data.value }}</b-link>
            </template>
            <template #cell(primaryPrice)="data">
              {{ data.value == null || data.value === 0 ? data.item.price : data.value }}
            </template>
            <template #cell(productName)="data">
              {{ data.value == null ? 'test' : data.value }}
            </template>
          </b-table-lite>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-10">
            <b-row>

              <!-- Col: Sales Persion -->
              <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3"
                  order="2"
                  order-md="1"
              >

              </b-col>

              <!-- Col: Total -->
              <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ msg('Subtotal') }}:
                    </p>
                    <p class="invoice-total-amount" style="display: inline">
                      {{ (transaction.amount / 1.1).toFixed(2) }} {{ currencySymbol }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ msg('Tax') + '(' + vatDisplay + '%)' }}:
                    </p>
                    <p class="invoice-total-amount">
                      {{ (transaction.amount - (transaction.amount / 1.1)).toFixed(2) }} {{ currencySymbol }}
                    </p>
                  </div>
                  <div class="invoice-total-item" v-if="order.tax > 0">
                    <p class="invoice-total-title">
                      {{ msg('Tax') }}:
                    </p>
                    <p class="invoice-total-amount">
                      {{ order.tax }}%
                    </p>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ msg('Total') }}:
                    </p>
                    <p class="invoice-total-amount">
                      {{ (transaction.amount).toFixed(2) }} {{ currencySymbol }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing" v-if="transaction.system_comment != null">

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0" v-if="transaction.system_comment != null">
            <span class="font-weight-bold">{{ msg('Note') }}: </span>
            <span>{{ transaction.system_comment }}</span>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
          cols="12"
          md="4"
          xl="3"
          class="invoice-actions"
      >
        <b-card>

          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <b-icon icon="MailIcon"/>
            </b-input-group-prepend>
            <b-form-input
                v-model="email"
                placeholder="Email"
            />
          </b-input-group>
          <!-- Button: Send Invoice -->
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-toggle.sidebar-send-invoice
              variant="primary"
              class="mb-75"
              block
              @click="sendInvoice(transaction.id)"
          >
            {{ msg('Send Invoice') }}
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-toggle.sidebar-send-invoice
                    variant="success"
                    class="mb-75"
                    block
                    @click="openPdfModal"
          >
            {{ msg('Regenerate Invoice') }}
          </b-button>
          <!-- Button: Download -->
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mb-75"
              block
              @click="downloadInvoice(transaction.id)"
          >
            Download
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <div class="text-center" v-else>
      <b-card no-body v-if="error == null">
        <div class="p-2">
          <b-spinner label="Spinning" variant="primary"/>
          {{ msg('loading') }}
        </div>

      </b-card>
      <b-alert v-else variant="danger" class="p-2" show><i class="fas fa-exclamation-triangle mx-1"></i> {{ error }}
      </b-alert>
    </div>
    <b-modal v-model="pdfModal.show" :id="pdfModal.id"
             :title="msg('You are about to regenerated the invoice. Are you sure?')"
             @ok="generatePdfInvoice()"
             ok-variant="danger" :cancel-title="msg('cancel')" @hide="resetPdfModal">
    </b-modal>
  </section>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'invoice',
  components: {},
  data() {
    return {
      id: 0,
      email: null,
      loaded: false,
      error: null,
      vatDisplay: 0,
      tabIndex: 0,
      transaction: {
        id: null,
        buid: 0,
        creationDate: null,
      },
      downloadUrl: "",
      order: {},
      tableData: [],
      client: {},
      pdfModal: {
        id: 'pdf-modal',
        title: '',
        content: '',
        show: false
      },
    }
  },
  created() {
    if (this.$route.params.id == null) {
      return this.error = 'Invalid transaction id'
    }
    this.name = this.$route.params.name
    this.id = this.$route.params.id
    this.onLoad()
  },
  computed: {
    ...mapGetters('data', ['getBranch', 'getCountry', 'currentBranchCurrency']),
    hasClient() {
      return this.client != null && this.client.id > 0;
    },
    invoiceBranch() {
      return this.getBranch(this.transaction.buid);
    },
    currencySymbol() {
      return '€';
    },
    shippingAddress() {
      if (this.order.shipping == null)
        return {};
      return this.order.shipping;
    }
  },
  methods: {
    ...mapActions('monetary', ['getMonetaryById', 'sendInvoiceToMail', 'getPdfInvoiceUrl']),
    ...mapActions('order', ['getOrderById']),
    ...mapActions('realEstateContracts', ['createPdfInvoiceContract']),
    ...mapActions('clients', {'getClient': 'get'}),
    onLoad() {
      let $this = this

      this.getMonetaryById(this.id)
          .then(this.onInvoiceUpdated, this.onError)
    },
    generatePdfInvoice() {
      let $this = this

      this.createPdfInvoiceContract(this.transaction.dynamicId)
          .then((response) => {
            $this.onLoad()
          })
    },
    resetPdfModal() {
      this.pdfModal.show = false;
    },
    onInvoiceUpdated(transaction) {
      let $this = this
      $this.transaction = transaction
      this.tableData = []
      Promise.all([this.getClient({id: transaction.clientId})]).then((response) => {
        $this.client = response[0].client;
        $this.loaded = true;

        const contract = transaction.data["contract"];
        const property = transaction.data["property"];
        const building = transaction.data["building"];

        let vat = 1.1;  // Default VAT (10%)
        this.vatDisplay = 10;
        if (building != null && building.vat != null) {
          vat = 1 + (building.vat / 100);
          this.vatDisplay = building.vat;
        }

        const newItem = {
          productName: property.address + ' ' + property.apt,
          amount: contract.bnbStyleRent != null ? contract.bnbStyleRent.totalNights : 1,
          nettoPrice: contract.bnbStyleRent != null ? (contract.bnbStyleRent.totalPayment / vat).toFixed(2) : 0,
          tax: contract.bnbStyleRent != null ? ((contract.bnbStyleRent.totalPayment) - (contract.bnbStyleRent.totalPayment / vat)).toFixed(2) : 0,
          price: contract.bnbStyleRent != null ? (contract.bnbStyleRent.totalPayment).toFixed(2) : 0
        };

        $this.tableData.push(newItem);

      }, this.onError);


    },/* onInvoiceUpdated(transaction) {
      let $this = this
      $this.transaction = transaction
      if (transaction.clientId != null && transaction.clientId > 0) {
        Promise.all([this.getClient({id: transaction.clientId}), this.getOrderById(transaction.orderId)]).then((response) => {
          $this.client = response[0].client;
          $this.order = response[1];
          $this.loaded = true;
        }, this.onError);

      } else {
        Promise.all([this.getOrderById(transaction.orderId)]).then((response) => {
          $this.order = response[0];
          $this.loaded = true;
        }, this.onError);

      }

    },*/
    onError(message) {
      this.error = message;
    },
    sendInvoice(id) {
      if (this.email == null) {
        this.email = ''
      }
      this.sendInvoiceToMail({id: id, email: this.email})
    },
    openPdfModal() {
      this.pdfModal.show = true;
    },
    downloadInvoice(id) {
      let $this = this;
      this.getPdfInvoiceUrl(id).then(
          (url) => {
            $this.downloadUrl = url
            window.open($this.downloadUrl, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
          }
      );

    },
  },

}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
